.main{
    width: 50%;
    margin: auto;
    padding: 20px 0;
    color: white;
}

.divider{
    margin: 40px 0 20px;
}

@media only screen and (max-width: 950px) {
    .main {
      width: 80%
    }
}

.user-agreement-list{
    text-align: left;
    list-style-type: none;
}

.privacy-list{
    text-align: left;
}

a{
    color: white
}

.grid-item{
    border-right: 0.5px solid grey;
    border-bottom: 0.5px solid grey;
    text-align: left;
    line-height: 20px;
    font-size: 14px;
    font-family: 'Open Sans',arial,sans-serif;
    font-weight: 400;
    padding: 20px 15px;
}

.icon-outer{
    width: 40px;
    height: 40px; 
    margin: auto; 
    border-radius: 50%; 
    background-color: #444;
}

.icon{
    margin: auto;
    color: white;
    font-size: 10px;
    line-height: 40px;
    text-align: center;
    display: block !important;
    width: 25px !important;
    height: 40px !important;
}

.role-item{
    margin-bottom: 25px;
}

.rating-title{
   
    color: rgb(254, 209, 8)
}

.rating-content{
    color: white
}

.link-title{
    color: white
}

.site-links a{
    text-decoration: none;
    margin-bottom: 15px;
    color: #fed108
}

.twitter-icon{
    width: 40px; 
    height: 40px;
    border-radius: 50%;
    background-color: #fed108;
}

.twitter-svg{
    margin: auto;
    color: black;
    font-size: 10px;
    line-height: 40px;
    display: block !important;
    width: 25px !important;
    height: 40px !important;
}

.instagram-icon{
    width: 40px; 
    height: 40px;
    border-radius: 50%;
    background-color: #fed108;
}

.instagram-svg{
    margin: auto;
    color: black;
    font-size: 10px;
    line-height: 40px;
    display: block !important;
    width: 25px !important;
    height: 40px !important;
}

.form-item{
    margin-bottom: 10px
}

.form-button{
    background-color: #fed108;
    color: black;
    border: 0px;
    padding: 10px 15px; 
}

.form-button:hover{
    cursor: pointer;
    background-color: black;
    color: white;
}
