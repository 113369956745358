@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

.center-xy {
  width: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
}

*, *:before, *:after {
  box-sizing: inherit;
}


.handle {
  background: #ffe500;
  width: 14px;
  height: 30px;
  top: 0;
  left: 0;
  margin-top: 1px;
  display: inline-block;
}

#cb-replay {
  fill: #666;
  width: 20px;
  margin: 15px;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow: inherit;
  cursor: pointer;
}