.ReactTags__tagInputField{
    border: 1px solid silver;
    border-radius: 10px;
    outline: none;
    padding: 10px 15px;
    margin-top: 10px;
}


.tag-wrapper{
    margin: 10px 6px;
}

.ReactTags__remove{
    border: 1px solid silver;
    background-color: rgb(233, 233, 233);
    border-radius: 10px;
}