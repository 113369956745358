.pagination {
    display: inline-block;
}

.pagination li {
    padding: 0.5rem;
    margin: 0 3px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #888;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 2px;
    min-width: 1rem;
}

.pagination .active{
    background-color: #fed108;
    color: black;
}

.pagination a{
    width: 100%;
    height: 100%;
    outline: none;
}

.pagination .selected a {
    box-shadow: 0 0 2px #1b7d9e;
    background-color: #f3fcff;
}

.pagination .disabled > a {
    color: #888;
    border-color: #eee;
    cursor: default;
}

.pagination .break {
    margin: 0 8px;
}
