figure{
    background-attachment: fixed;
}

.card > header{
    background-color: transparent;
}
.MuiRadio-colorPrimary{
    color: white !important
}
.MuiRadio-colorPrimary.Mui-checked{
    color: #fed108 !important
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


.tag-wrapper {
    color: white
}

.MuiDrawer-paper .tag-wrapper{
    color: black !important
}

.tag-wrapper > button {
    margin-left: 4px
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color: black !important;
}

.MuiCheckbox-root.Mui-disabled{
    color: silver !important;
}

