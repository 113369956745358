.main{
    margin-bottom: 2%;
}

.item{
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.card-info{
    color: white;
}

.card-body{
    text-align: left;
    margin: 20px 0px;
}

.MuiCardContent-root > .MuiTypography-root{
    color: white
}

.card-title{
    text-align: left;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-date{
    color: #888;
    overflow-wrap: break-word;
}

.card-text{
    color: #888;
    text-align: left;
    overflow-wrap: break-word;
}

.card-tags{
    text-align: left;
}

.tag{
    background-color: black !important;
    border-radius: 0px !important;
    border: 1px solid #888 !important;
    color: #888 !important;
}

.button{
    margin-top: 15px !important;
    border-color:#fed108 !important;
    color: #fed108 !important;
}

.button:hover{
    border-color: white !important;
    color: white !important;
}